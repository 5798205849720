<template>
  <v-app :dark="dark">
    <template v-if="isAuthenticated">
      <v-toolbar
        app
        color="green darken-4"
        id="toolbar-image1"
        scroll-off-screen
      >
        <v-toolbar-side-icon
          class="white--text"
          @click.stop="drawer = !drawer"
        />
        <v-menu
          v-model="dealerMenu"
          offset-y
          lazy
          transition="slide-y-transition"
          @click.native="getResources()"
        >
          <v-toolbar-title slot="activator">
            <span class="white--text">{{ title }}</span>
            <v-icon class="white--text">
              arrow_drop_down
            </v-icon>
          </v-toolbar-title>
          <v-list
            dense
            two-line
          >
            <v-subheader v-text="'Resources'" />
            <v-list-tile
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-cash</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="ecash" />
                <v-list-tile-sub-title>
                  Available e-recharge
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-if="!isPureNuDealer()"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-pin-outline</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="d225PinText" />
                <v-list-tile-sub-title>D225 PIN</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-if="dpPin > 0"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-pin</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="dpPinText" />
                <v-list-tile-sub-title>Dealer (DP) PIN</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-if="dealerPin > 0"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-pin</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="dealerPinText" />
                <v-list-tile-sub-title>Dealer PIN</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-if="bPin > 0"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-pin</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="bPinText" />
                <v-list-tile-sub-title>BPin</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-if="sdPin > 0"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-pin</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="sdPinText" />
                <v-list-tile-sub-title>Subdealer PIN</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-if="isNuDealer()"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-pin</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="nuPinText" />
                <v-list-tile-sub-title>NU PIN</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <template v-if="b89dbDealer">
              <v-divider />
              <v-subheader v-text="'Black Resources'" />
              <v-list-tile
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-pin-outline</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title v-html="b89dbPinText" />
                  <v-list-tile-sub-title>B89DB PIN</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </v-menu>
        <v-btn
          v-if="isImpersonated"
          flat
          color="orange"
          text-color="white"
          @click="exitImpersonation()"
        >
          Exit Impersonation
        </v-btn>
        <v-spacer />
        <v-toolbar-items>
          <!--v-btn
            v-if="hash && !isImpersonated"
            ripple
            icon
            @click.stop="toggleTawk()"
          >
            <v-icon dark>
              mdi-chat
            </v-icon>
          </v-btn-->
          <v-btn
            v-if="isDominant && showSwitchDashboard"
            ripple
            flat
            class="transparent"
            @click.stop="dashboardSheet = true"
          >
            <span class="pr-2 hidden-xs-only">Switch Dashboard</span>
            <v-icon dark>
              mdi-view-list
            </v-icon>
          </v-btn>
          <v-divider
            v-if="isDominant && showSwitchDashboard"
            vertical
          />
          <v-menu
            lazy
            transition="slide-y-transition"
            offset-y
            left
          >
            <v-btn
              slot="activator"
              class="transparent"
            >
              <span class="pr-2 hidden-xs-only">{{ myName }}</span>
              <v-badge
                v-if="avatar && lead"
                overlap
                bottom
                left
                dark
                small
              >
                <template #badge>
                  <v-icon>mdi-star</v-icon>
                </template>
                <v-avatar size="40px">
                  <v-img :src="avatar" />
                </v-avatar>
              </v-badge>
              <v-avatar
                v-else-if="avatar"
                size="40px"
              >
                <v-img :src="avatar" />
              </v-avatar>
              <v-icon
                v-else
                dark
              >
                mdi-dots-vertical
              </v-icon>
            </v-btn>
            <v-card flat>
              <v-list dense>
                <v-subheader v-text="'Account'" />
                <v-list-tile
                  v-if="isDominant"
                  to="/profile"
                  ripple
                >
                  <v-list-tile-avatar>
                    <v-icon>mdi-face-man-profile</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title v-html="'Profile'" />
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  ripple
                  @click.stop="logout()"
                >
                  <v-list-tile-avatar>
                    <v-icon>{{ logoutItem.icon }}</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    {{ logoutItem.hint.html }}
                  </v-list-tile-title>
                </v-list-tile>
                <v-divider />
                <v-subheader v-text="'Display'" />
                <v-list-tile
                  ripple
                  @click="toggleTheme"
                >
                  <v-list-tile-avatar>
                    <v-icon>mdi-weather-partly-cloudy</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title v-html="'Toggle Scheme'" />
                  </v-list-tile-content>
                </v-list-tile>
                <v-subheader v-text="'Version'" />
                <v-list-tile>
                  <v-list-tile-avatar>
                    <v-icon>mdi-file-replace</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title v-html="'V2.17.0'" />
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-card>
          </v-menu>
        </v-toolbar-items>
      </v-toolbar>
      <v-navigation-drawer
        v-model="drawer"
        :mini-variant="miniVariant"
        :clipped="clipped"
        temporary
        app
      >
        <v-toolbar
          flat
          class="transparent"
        >
          <v-list class="pa-0">
            <v-list-tile>
              <!--router-link
                to="/"
                exact
              -->
              <v-list-tile-avatar :size="menuSize">
                <img src="@/assets/emblem2.png">
              </v-list-tile-avatar>
              <!--/router-link-->
              <v-menu
                v-if="!miniVariant"
                transition="v-slide-y-transition"
                nudge-bottom="30"
              >
                <v-list-tile-title slot="activator">
                  <strong>OneSYS</strong><v-icon>mdi-menu-down</v-icon>
                </v-list-tile-title>
                <v-list
                  class="grey darken-2"
                  dark
                  dense
                >
                  <v-list-tile
                    v-for="item in onesysItems"
                    :key="item.title"
                    v-bind="item.link"
                    ripple
                  >
                    <v-list-tile-avatar>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title :style="{ color: 'white' }">
                      {{ item.title }}
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
              <v-spacer />
              <v-list-tile-action>
                <v-btn
                  v-if="!miniVariant"
                  icon
                  @click.stop="miniVariant = !miniVariant"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-toolbar>
        <v-list
          class="pt-0"
          two-line
          ripple
          dense
        >
          <v-list-tile
            v-if="miniVariant"
            ripple
            @click="miniVariant = !miniVariant"
          >
            <v-list-tile-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider />
          <template v-if="activeDashboard === xforceDashboardName">
            <template v-if="xforceAdmin">
              <v-divider />
              <v-subheader>X-Force Admin</v-subheader>
              <v-list-tile
                to="/xforce/administration"
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-store-outline</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content v-show="!miniVariant">
                  <v-list-tile-title>Administration</v-list-tile-title>
                  <v-list-tile-sub-title>
                    Manage locations &amp; dealers
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                to="/xforce/daily_report"
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-chart-box-outline</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content v-show="!miniVariant">
                  <v-list-tile-title>Daily Report</v-list-tile-title>
                  <v-list-tile-sub-title>
                    Admin daily reports
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
            <v-divider />
            <v-subheader>X-Force Leader</v-subheader>
            <v-list-tile
              to="/xforce/schedule"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-head-plus-outline</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>Scheduling</v-list-tile-title>
                <v-list-tile-sub-title>Dealer Scheduling</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              to="/xforce/track"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-radar</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>Tracking</v-list-tile-title>
                <v-list-tile-sub-title>Track activities</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <template v-else-if="activeDashboard === supportDashboardName">
            <!-- <v-list-tile
              v-if="showEpic"
              @click.stop="showRetiringEpicDialog = true"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>Epic Home</v-list-tile-title>
                <v-list-tile-sub-title>Old dashboard</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile> -->
            <v-divider />
            <v-subheader>Internal Support</v-subheader>
            <v-list-tile
              to="/support/subscription_list"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-table-search</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>Subscriptions</v-list-tile-title>
                <v-list-tile-sub-title>
                  List &amp; find subscriptions
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              to="/support/family"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-human-male-female-child</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>FamilyPlan</v-list-tile-title>
                <v-list-tile-sub-title>
                  FamilyPlan details
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              to="/support/mysiswa"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-school</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>My Siswa</v-list-tile-title>
                <v-list-tile-sub-title>My Siswa</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-show="miniVariant"
              to="/support/dmt_kits"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-face-agent</v-icon>
              </v-list-tile-avatar>
            </v-list-tile>
            <v-list-group v-show="!miniVariant">
              <v-list-tile slot="activator">
                <v-list-tile-avatar>
                  <v-icon>mdi-face-agent</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>DMT Kits</v-list-tile-title>
                  <v-list-tile-sub-title>
                    Dealer support tools
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <!--v-list-group
                v-if="hasRealmRole(['dmt'])"
                no-action
                sub-group>
                <v-list-tile slot="activator">
                  <v-list-tile-title>Handle Case</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-for="(item, i) in supportTools"
                  :key="i"
                  v-bind="item.link">
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.title"/>
                    <v-list-tile-sub-title v-text="item.subTitle"/>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group-->
              <v-list-group
                no-action
                sub-group
              >
                <v-list-tile slot="activator">
                  <v-list-tile-title>Reports</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-for="(item, i) in reports"
                  :key="i"
                  v-bind="item.link"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.title" />
                    <v-list-tile-sub-title v-text="item.subTitle" />
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
            </v-list-group>
            <v-list-group no-action>
              <v-list-tile slot="activator">
                <v-list-tile-avatar>
                  <v-icon>mdi-chart-line</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-title>User Statistics</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-for="(item, i) in statistics"
                :key="i"
                v-bind="item.link"
              >
                <v-list-tile-content>
                  <v-list-tile-title v-text="item.title" />
                </v-list-tile-content>
              </v-list-tile>
            </v-list-group>
            <v-list-group no-action>
              <v-list-tile slot="activator">
                <v-list-tile-avatar>
                  <v-icon>mdi-account-arrow-down-outline</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-title>Mnp</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-for="(item, i) in mnpKits"
                :key="i"
                v-bind="item.link"
              >
                <v-list-tile-content>
                  <v-list-tile-title v-text="item.title" />
                </v-list-tile-content>
              </v-list-tile>
            </v-list-group>
            <v-list-tile
              to="/support/ad_achievement"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-account-star-outline</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>AD Achievement</v-list-tile-title>
                <v-list-tile-sub-title>
                  Area Dealer Achievements
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <template v-if="testxox">
              <v-list-tile
                to="/support/ssm_verification"
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-file-document-check</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content v-show="!miniVariant">
                  <v-list-tile-title>SSM Verification</v-list-tile-title>
                  <v-list-tile-sub-title>
                    SSM Document Verification
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
            <v-list-tile
              to="/support/dealer_terminated_list"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-account-off</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>Dealer List</v-list-tile-title>
                <v-list-tile-sub-title>
                  Dealer Demoted/Terminated List
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              to="/support/pushup_user_list"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-clipboard-list-outline</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>User List</v-list-tile-title>
                <v-list-tile-sub-title>
                  User Demoted/Terminated List
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              to="/support/simpack_list"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-sim</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>Simpack List</v-list-tile-title>
                <v-list-tile-sub-title>
                  Dealer Simpack List
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              to="/support/topup_history"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-clipboard-text-clock-outline</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>Topup History</v-list-tile-title>
                <v-list-tile-sub-title>
                  Topup History last 3 Month
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-if="permissionSetIntroducer || hasRealmRole(['devop'])"
              to="/support/set_introducer"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-account-arrow-up-outline</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>Set Introducer</v-list-tile-title>
                <v-list-tile-sub-title>
                  For subscriber without introducer
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-if="permissionSetIntroducer || hasRealmRole(['devop'])"
              to="/support/activate_user"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-account-reactivate-outline</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>Activate User</v-list-tile-title>
                <v-list-tile-sub-title>
                  Activate Terminated User
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              to="/support/dealer_news"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-newspaper-plus</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>Dealer News</v-list-tile-title>
                <v-list-tile-sub-title>
                  Adding news for dealer
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <template v-else-if="activeDashboard === operationDashboardName">
            <v-divider />
            <v-subheader>Operation</v-subheader>
            <v-list-tile
              v-show="miniVariant"
              to="/operation/operation_kits"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-engine</v-icon>
              </v-list-tile-avatar>
            </v-list-tile>
            <v-list-group v-show="!miniVariant">
              <v-list-tile slot="activator">
                <v-list-tile-avatar>
                  <v-icon>mdi-engine</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>Operation Kits</v-list-tile-title>
                  <v-list-tile-sub-title>Operation tools</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-group
                no-action
                sub-group
              >
                <v-list-tile slot="activator">
                  <v-list-tile-title>Corporate</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-for="(item, i) in corporateItems"
                  :key="i"
                  v-bind="item.link"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.title" />
                    <v-list-tile-sub-title v-text="item.subTitle" />
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
            </v-list-group>
            <v-list-tile
              to="/operation/transfer_hierarchy"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-family-tree</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>Transfer Hierarchy</v-list-tile-title>
                <v-list-tile-sub-title>
                  Transfer hierarchy between dealers
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              to="/operation/topup_staff"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-cellphone-charging</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>Topup Benefit</v-list-tile-title>
                <v-list-tile-sub-title>
                  Staff Topup Benefit
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-show="miniVariant"
              to="/jombantu"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-charity</v-icon>
              </v-list-tile-avatar>
            </v-list-tile>
            <v-list-group v-show="!miniVariant">
              <v-list-tile slot="activator">
                <v-list-tile-avatar>
                  <v-icon>mdi-charity</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content v-show="!miniVariant">
                  <v-list-tile-title>Jom Bantu</v-list-tile-title>
                  <v-list-tile-sub-title>Donation</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                to="/operation/jb_update_details"
                avatar
                ripple
              >
                <v-list-tile-avatar />
                <v-list-tile-content v-show="!miniVariant">
                  <v-list-tile-title>Upload Details</v-list-tile-title>
                  <v-list-tile-sub-title>
                    Upload Details from school
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                to="/operation/jb_list_student"
                avatar
                ripple
              >
                <v-list-tile-avatar />
                <v-list-tile-content v-show="!miniVariant">
                  <v-list-tile-title>Student Listing</v-list-tile-title>
                  <v-list-tile-sub-title>Student list</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list-group>
            <v-list-tile
              to="/operation/ifinancing"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-hand-coin-outline</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content v-show="!miniVariant">
                <v-list-tile-title>I-Financing</v-list-tile-title>
                <v-list-tile-sub-title>
                  Financing Transfer
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <template v-else-if="activeDashboard === managementDashboardName">
            <v-divider />
            <v-subheader>Management</v-subheader>
            <v-list-tile
              v-show="miniVariant"
              to="/management/management_kits"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-chart-areaspline</v-icon>
              </v-list-tile-avatar>
            </v-list-tile>
            <v-list-group v-show="!miniVariant">
              <v-list-tile slot="activator">
                <v-list-tile-avatar>
                  <v-icon>mdi-chart-areaspline</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>Management Report</v-list-tile-title>
                  <v-list-tile-sub-title>
                    Reports &amp; Statistics
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-group
                no-action
                sub-group
              >
                <v-list-tile slot="activator">
                  <v-list-tile-title>Daily Excerpt</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-for="(item, i) in dailyReports"
                  :key="i"
                  v-bind="item.link"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.title" />
                    <v-list-tile-sub-title v-text="item.subTitle" />
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
              <v-list-group
                no-action
                sub-group
              >
                <v-list-tile
                  slot="activator"
                  dense
                >
                  <v-list-tile-title>Monthly Excerpt</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-for="(item, i) in monthlyReports"
                  :key="i"
                  v-bind="item.link"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.title" />
                    <v-list-tile-sub-title v-text="item.subTitle" />
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
              <v-list-group
                no-action
                sub-group
              >
                <v-list-tile
                  slot="activator"
                  dense
                >
                  <v-list-tile-title>Reimbursement Excerpt</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-for="(item, i) in reimbursementReports"
                  :key="i"
                  v-bind="item.link"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.title" />
                    <v-list-tile-sub-title v-text="item.subTitle" />
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
            </v-list-group>
          </template>
          <template v-else-if="activeDashboard === financeDashboardName">
            <v-divider />
            <v-subheader>Finance</v-subheader>
            <v-list-tile
              v-show="miniVariant"
              to="/finance/finance_kits"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-finance</v-icon>
              </v-list-tile-avatar>
            </v-list-tile>
            <v-list-group v-show="!miniVariant">
              <v-list-tile slot="activator">
                <v-list-tile-avatar>
                  <v-icon>mdi-finance</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>Finance</v-list-tile-title>
                  <v-list-tile-sub-title>Finance</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-group
                no-action
                sub-group
              >
                <v-list-tile slot="activator">
                  <v-list-tile-title>Statistics</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-for="(item, i) in financeStatistics"
                  :key="i"
                  v-bind="item.link"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.title" />
                    <v-list-tile-sub-title v-text="item.subTitle" />
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
            </v-list-group>
          </template>
          <template v-else-if="activeDashboard === snmDashboardName">
            <v-divider />
            <v-subheader>Snm</v-subheader>
            <v-list-tile
              v-show="miniVariant"
              to="/snm/snm_kits"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-tile-avatar>
            </v-list-tile>
            <v-list-group v-show="!miniVariant">
              <v-list-tile slot="activator">
                <v-list-tile-avatar>
                  <v-icon>mdi-account-outline</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>Snm</v-list-tile-title>
                  <v-list-tile-sub-title>Snm</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-group
                no-action
                sub-group
              >
                <v-list-tile slot="activator">
                  <v-list-tile-title>Dealership</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-for="(item, i) in snmStatistics"
                  :key="i"
                  v-bind="item.link"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.title" />
                    <v-list-tile-sub-title v-text="item.subTitle" />
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
            </v-list-group>
          </template>
          <template v-else-if="activeDashboard === technicalDashboardName">
            <v-divider />
            <v-subheader>Technical</v-subheader>
            <v-list-tile
              v-show="miniVariant"
              to="/technical/technical_kits"
              avatar
              ripple
            >
              <v-list-tile-avatar>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-tile-avatar>
            </v-list-tile>
            <v-list-group v-show="!miniVariant">
              <v-list-tile slot="activator">
                <v-list-tile-avatar>
                  <v-icon>mdi-account-outline</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>Technical</v-list-tile-title>
                  <v-list-tile-sub-title>Technical</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-group
                no-action
                sub-group
              >
                <v-list-tile slot="activator">
                  <v-list-tile-title>Dealer</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  v-for="(item, i) in technicalStatistics"
                  :key="i"
                  v-bind="item.link"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.title" />
                    <v-list-tile-sub-title v-text="item.subTitle" />
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
            </v-list-group>
          </template>
          <template v-else>
            <template v-if="hasRealmRole(['subscriber'])">
              <v-list-tile
                to="/"
                exact
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-view-dashboard-outline</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>Home</v-list-tile-title>
                  <v-list-tile-sub-title>New dashboard</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <!-- <v-list-tile
                v-if="isDominant && showEpic"
                @click.stop="showRetiringEpicDialog = true"
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-view-dashboard</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>Epic Home</v-list-tile-title>
                  <v-list-tile-sub-title>Old dashboard</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile> -->
            </template>
            <template v-if="areaDealer">
              <v-list-group>
                <v-list-tile slot="activator">
                  <v-list-tile-avatar>
                    <v-icon>mdi-account-tie</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>Area Dealer</v-list-tile-title>
                    <v-list-tile-sub-title>
                      Area Dealer Kits
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  to="/contractAD"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar />
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>Contract</v-list-tile-title>
                    <v-list-tile-sub-title>
                      Area Dealer Contract
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  to="/adRegion"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar />
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>Reports</v-list-tile-title>
                    <v-list-tile-sub-title>
                      Area Dealer States & Districts
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
            </template>
            <template v-if="masterDealer">
              <v-list-group>
                <v-list-tile slot="activator">
                  <v-list-tile-avatar>
                    <v-icon>mdi-account-tie-hat</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>Master Dealer</v-list-tile-title>
                    <v-list-tile-sub-title>
                      Master Dealer Kits
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  to="/mdState"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar />
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>Reports</v-list-tile-title>
                    <v-list-tile-sub-title>
                      Master Dealer States & Districts
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
            </template>
            <template v-if="hasRealmRole(['dealer'])">
              <v-divider />
              <v-subheader>Dealer Essentials</v-subheader>
              <v-list-tile
                to="/activity"
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-book-open</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content v-show="!miniVariant">
                  <v-list-tile-title>Activities</v-list-tile-title>
                  <v-list-tile-sub-title>
                    View business activities
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                to="/qrcode"
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-qrcode</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content v-show="!miniVariant">
                  <v-list-tile-title>QR Code</v-list-tile-title>
                  <v-list-tile-sub-title>
                    Dealer affiliate QR code
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                v-if="isDominant && !isSLD"
                to="/kpi"
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-clock-start</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content v-show="!miniVariant">
                  <v-list-tile-title>KPI</v-list-tile-title>
                  <v-list-tile-sub-title>
                    Key Performance Indicator
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                v-if="isSLD"
                to="/kpisld"
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-clock-start</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content v-show="!miniVariant">
                  <v-list-tile-title>KPI SLD</v-list-tile-title>
                  <v-list-tile-sub-title>
                    SLD Key Performance Indicator
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                v-show="miniVariant"
                to="/top_performance"
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-trophy</v-icon>
                </v-list-tile-avatar>
              </v-list-tile>
              <v-list-group v-show="!miniVariant">
                <v-list-tile slot="activator">
                  <v-list-tile-avatar>
                    <v-icon>mdi-trophy</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>Top Performance</v-list-tile-title>
                    <v-list-tile-sub-title>
                      Top 50 Performance
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  to="/monthly_top_performance"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar />
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>Monthly </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  to="/daily_top_performance"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar />
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>Daily </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
              <v-list-tile
                v-show="miniVariant"
                to="/dealer_kits"
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>business_center</v-icon>
                </v-list-tile-avatar>
              </v-list-tile>
              <v-list-group v-show="!miniVariant">
                <v-list-tile slot="activator">
                  <v-list-tile-avatar>
                    <v-icon>business_center</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Dealer Kits</v-list-tile-title>
                    <v-list-tile-sub-title>
                      Business operation tools
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-group
                  v-if="hasRealmRole(['sales'])"
                  no-action
                  sub-group
                >
                  <v-list-tile slot="activator">
                    <v-list-tile-title>Prepaid</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-for="(item, i) in prepaidItems"
                    :key="i"
                    v-bind="item.link"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title v-text="item.title" />
                      <v-list-tile-sub-title v-text="item.subTitle" />
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list-group>
                <v-list-group
                  v-if="!b10Dealer && !isPureNuDealer()"
                  no-action
                  sub-group
                >
                  <v-list-tile
                    slot="activator"
                    dense
                  >
                    <v-list-tile-title>Black</v-list-tile-title>
                  </v-list-tile>
                  <template v-if="blackDealer">
                    <v-list-tile
                      v-for="(item, i) in blackItems"
                      :key="i"
                      v-bind="item.link"
                    >
                      <v-list-tile-content>
                        <v-list-tile-title v-text="item.title" />
                        <v-list-tile-sub-title v-text="item.subTitle" />
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                  <template v-else>
                    <v-list-tile
                      v-for="(item, i) in noBlackItems"
                      :key="i"
                      v-bind="item.link"
                    >
                      <v-list-tile-content>
                        <v-list-tile-title v-text="item.title" />
                        <v-list-tile-sub-title v-text="item.subTitle" />
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                </v-list-group>
                <v-list-group
                  no-action
                  sub-group
                >
                  <v-list-tile slot="activator">
                    <v-list-tile-title>E-Recharge</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-for="(item, i) in ecashItems"
                    :key="i"
                    v-bind="item.link"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title v-text="item.title" />
                      <v-list-tile-sub-title v-text="item.subTitle" />
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list-group>
                <v-list-group
                  v-if="!basicDealer && isDominant && !isSLD"
                  no-action
                  sub-group
                >
                  <v-list-tile slot="activator">
                    <v-list-tile-title>Dealership</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-for="(item, i) in dealershipItems"
                    :key="i"
                    v-bind="item.link"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title v-text="item.title" />
                      <v-list-tile-sub-title v-text="item.subTitle" />
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list-group>
                <v-list-tile to="/reload">
                  <v-list-tile-avatar />
                  <v-list-tile-content>
                    <v-list-tile-title>Topup/Pay Bill</v-list-tile-title>
                    <v-list-tile-sub-title>Reload number</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile to="/upload_simcard">
                  <v-list-tile-avatar />
                  <v-list-tile-content>
                    <v-list-tile-title>Upload SIM Pack</v-list-tile-title>
                    <v-list-tile-sub-title>
                      SIM for self activation
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  v-if="uatTester || hasRealmRole(['devop'])"
                  to="/pickup_order"
                >
                  <v-list-tile-avatar />
                  <v-list-tile-content>
                    <v-list-tile-title>Pickup Order</v-list-tile-title>
                    <v-list-tile-sub-title>
                      Simcard pickup order
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
              <template v-if="isDominant">
                <v-list-tile
                  v-show="miniVariant"
                  to="/subscriber_group"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar>
                    <v-icon>mdi-sitemap</v-icon>
                  </v-list-tile-avatar>
                </v-list-tile>
                <v-list-group v-show="!miniVariant">
                  <v-list-tile slot="activator">
                    <v-list-tile-avatar>
                      <v-icon>mdi-sitemap</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Subscriber Group</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Monitor subscriber group
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    v-for="(item, i) in subscriberGroup"
                    :key="i"
                    v-bind="item.link"
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content>
                      <v-list-tile-title v-text="item.title" />
                      <v-list-tile-sub-title v-text="item.subTitle" />
                    </v-list-tile-content>
                  </v-list-tile>
                  <template v-if="!isSLD">
                    <v-list-tile
                      to="/subscriber_performance"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>Performance</v-list-tile-title>
                        <v-list-tile-sub-title>
                          Group performance
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                </v-list-group>
                <v-list-tile
                  v-show="miniVariant"
                  to="/incentives"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar>
                    <v-icon>mdi-gift</v-icon>
                  </v-list-tile-avatar>
                </v-list-tile>
                <v-list-group v-show="!miniVariant">
                  <v-list-tile slot="activator">
                    <v-list-tile-avatar>
                      <v-icon>mdi-gift</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Incentives</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Dealer Incentives
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <template v-if="!isFc">
                    <v-list-group
                      no-action
                      sub-group
                    >
                      <v-list-tile slot="activator">
                        <v-list-tile-content v-show="!miniVariant">
                          <v-list-tile-title>RI</v-list-tile-title>
                          <v-list-tile-sub-title>
                            Royalty Incentive
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>
                      <v-list-tile
                        v-for="(item, i) in royaltyIncentiveItems"
                        :key="i"
                        v-bind="item.link"
                      >
                        <v-list-tile-content>
                          <v-list-tile-title v-text="item.title" />
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list-group>
                  </template>
                  <template v-if="!isSLD">
                    <v-list-tile
                      to="/recruitment_incentive"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>
                          Dealer Appointment
                        </v-list-tile-title>
                        <v-list-tile-sub-title>
                          Dealer Appointment Incentive
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      to="/incentive_ssp"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>
                          Special Season Pass
                        </v-list-tile-title>
                        <v-list-tile-sub-title>
                          SSP Incentive
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      to="/incentive_wawa"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>WAWA</v-list-tile-title>
                        <v-list-tile-sub-title>
                          Special Incentive Program
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      to="/incentive_perpaduan"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>Perpaduan</v-list-tile-title>
                        <v-list-tile-sub-title>
                          Special Incentive Program
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                  <template v-if="!isFc && !isSLD">
                    <v-list-tile
                      to="/prepaid_ai"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>Prepaid AI</v-list-tile-title>
                        <v-list-tile-sub-title>
                          Prepaid Activation Incentive
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      to="/black_ai"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>Black AI</v-list-tile-title>
                        <v-list-tile-sub-title>
                          Black Activation Incentive
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                  <v-list-tile
                    to="/income"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Income</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Income Statement
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <template v-if="isFc">
                    <v-list-tile
                      to="/fc_incentives"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>Fc Incentives</v-list-tile-title>
                        <v-list-tile-sub-title>
                          Detail Incentive Records
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                  <template v-if="isMySiswaSpecial">
                    <v-list-tile
                      to="/mysiswa_special_incentives"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>
                          MySiswa Incentives
                        </v-list-tile-title>
                        <v-list-tile-sub-title>
                          Detail Incentive Records
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                </v-list-group>
                <v-list-group v-show="!miniVariant">
                  <v-list-tile slot="activator">
                    <v-list-tile-avatar>
                      <v-icon>mdi-folder-information</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Downloads</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Documentation
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/onexapp_install"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Onexapp Install</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/dealer_guide"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Dealer Guide</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/subscriber_guide"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Subscriber Guide</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/marketing_tool"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Marketing Tool</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/shariah_matter"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Shari'ah Matters</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/business_registeration"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>
                        Business Registration
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/ownership_transfer"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Ownership Transfer</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <template v-if="dealerxox">
                    <v-list-tile
                      to="/cp58_statement"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>CP58 Statement</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                </v-list-group>
                <v-list-tile
                  v-show="miniVariant"
                  to="/incentives"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar>
                    <v-icon>mdi-gift</v-icon>
                  </v-list-tile-avatar>
                </v-list-tile>
                <v-list-tile
                  v-show="miniVariant"
                  to="/campaigns"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar>
                    <v-icon>mdi-wallet-giftcard</v-icon>
                  </v-list-tile-avatar>
                </v-list-tile>
                <v-list-group v-show="!miniVariant && !isSLD">
                  <v-list-tile slot="activator">
                    <v-list-tile-avatar>
                      <v-icon>mdi-wallet-giftcard</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Campaigns</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Running campaigns
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <!-- <v-list-tile
                    to="/campaign_senang_win"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Kempen Senang Win</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile> -->
                  <template v-if="tactical">
                    <v-list-tile
                      to="/campaign_tactical"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>Kempen Tactical</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                  <v-list-tile
                    to="/campaign_mega"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Dealer Mega Campaign</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/mnp_noncelcom"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Mnp Non-Celcom</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/unl35Campaign"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>UNL35 Campaign</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list-group>
                <v-list-tile
                  v-show="miniVariant"
                  to="/lead_menu"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar>
                    <v-icon>mdi-school</v-icon>
                  </v-list-tile-avatar>
                </v-list-tile>
                <v-list-group v-show="!miniVariant">
                  <v-list-tile slot="activator">
                    <v-list-tile-avatar>
                      <v-icon>mdi-school</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>XOX LEAD Program</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Learning Education & Development
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/training_materials"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Training Materials</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Download slides
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <!-- <v-list-tile
                      to="/lead"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>Certificates</v-list-tile-title>
                        <v-list-tile-sub-title>Download certificates</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      to="/credit_hours"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>Credit Hours</v-list-tile-title>
                        <v-list-tile-sub-title>View your score</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      to="/rsvp_lead"
                      avatar
                      ripple
                    >
                      <v-list-tile-avatar />
                      <v-list-tile-content v-show="!miniVariant">
                        <v-list-tile-title>RSVP Lead Class</v-list-tile-title>
                        <v-list-tile-sub-title>RSVP class</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile> -->
                </v-list-group>
                <v-list-tile
                  v-show="miniVariant"
                  to="/certificates"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar>
                    <v-icon>mdi-certificate</v-icon>
                  </v-list-tile-avatar>
                </v-list-tile>
                <v-list-group v-show="!miniVariant">
                  <v-list-tile slot="activator">
                    <v-list-tile-avatar>
                      <v-icon>mdi-certificate</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Certifications</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Dealer Certificates
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/lead"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>Lead</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Learning Education & Development
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile
                    to="/dcp_cert"
                    avatar
                    ripple
                  >
                    <v-list-tile-avatar />
                    <v-list-tile-content v-show="!miniVariant">
                      <v-list-tile-title>DCP</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Dealer Certification Program
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list-group>
              </template>
            </template>
            <template v-if="hasRealmRole(['subscriber']) && isDominant">
              <v-list-tile
                v-show="miniVariant"
                to="/ugu"
                avatar
                ripple
              >
                <v-list-tile-avatar>
                  <v-icon>mdi-account-group-outline</v-icon>
                </v-list-tile-avatar>
              </v-list-tile>
              <v-list-group v-show="!miniVariant && !isSLD">
                <v-list-tile slot="activator">
                  <v-list-tile-avatar>
                    <v-icon>mdi-account-group-outline</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>UGU</v-list-tile-title>
                    <v-list-tile-sub-title>
                      User Gets User
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  to="/ugu_list"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar />
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>List</v-list-tile-title>
                    <v-list-tile-sub-title>UGU List</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  to="/ugu_reloads"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar />
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>Reloads</v-list-tile-title>
                    <v-list-tile-sub-title>UGU Reloads</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  to="/ugu_rewards"
                  avatar
                  ripple
                >
                  <v-list-tile-avatar />
                  <v-list-tile-content v-show="!miniVariant">
                    <v-list-tile-title>Rewards</v-list-tile-title>
                    <v-list-tile-sub-title>UGU Reward</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
              <template v-if="demote">
                <v-list-group v-show="!miniVariant">
                  <v-list-tile slot="activator">
                    <v-list-tile-avatar>
                      <v-icon>business_center</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>Demoted Kits</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Business operation tools
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <!-- <v-list-tile to="/reload_demoted">
                    <v-list-tile-avatar />
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Topup/Pay Black Bill
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        Reload Prepaid &amp; Black number
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile> -->
                  <v-list-group
                    no-action
                    sub-group
                  >
                    <v-list-tile slot="activator">
                      <v-list-tile-title>E-Recharge</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile
                      v-for="(item, i) in ecashDemotedItems"
                      :key="i"
                      v-bind="item.link"
                    >
                      <v-list-tile-content>
                        <v-list-tile-title v-text="item.title" />
                        <v-list-tile-sub-title v-text="item.subTitle" />
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list-group>
                </v-list-group>
              </template>
            </template>
            <!--template v-if="hasRealmRole(['finance'])">
            <v-divider/>
            <v-subheader>Finance</v-subheader>
            <v-list-tile v-show="miniVariant" to="/finance/purchase_orders" avatar ripple>
              <v-list-tile-avatar>
                <v-icon>mdi-bank-outline</v-icon>
              </v-list-tile-avatar>
            </v-list-tile>
            <v-list-group
              v-show="!miniVariant">
              <v-list-tile slot="activator">
                <v-list-tile-avatar>
                  <v-icon>mdi-cart-outline</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content v-show="!miniVariant">
                  <v-list-tile-title>Purchase Orders</v-list-tile-title>
                  <v-list-tile-sub-title>Manage purchase orders</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                v-for="(item, i) in financeGroup"
                :key="i"
                v-bind="item.link">
                <v-list-tile-avatar/>
                <v-list-tile-content>
                  <v-list-tile-title v-text="item.title"/>
                  <v-list-tile-sub-title v-text="item.subTitle"/>
                </v-list-tile-content>
              </v-list-tile>
            </v-list-group>
          </template-->
          </template>
        </v-list>
      </v-navigation-drawer>
      <v-bottom-sheet v-model="dashboardSheet">
        <v-list
          two-line
          ripple
          dense
        >
          <v-subheader>Switch Dashboard</v-subheader>
          <v-divider />
          <v-list-tile
            v-for="dashboard in dashboards"
            :key="dashboard.title"
            @click.stop="switchDashboard(dashboard)"
          >
            <v-list-tile-avatar>
              <v-avatar
                size="32px"
                tile
              >
                <v-icon
                  :color="dashboard.color"
                  x-large
                >
                  {{ dashboard.icon }}
                </v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ dashboard.title }}</v-list-tile-title>
              <v-list-tile-sub-title>
                {{ dashboard.description }}
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-bottom-sheet>
    </template>
    <template v-else>
      <v-toolbar
        app
        inverted-scroll
        scroll-off-screen
      >
        <v-toolbar-title>
          <span>{{ title }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          flat
          @click.stop="login()"
        >
          Login
        </v-btn>
      </v-toolbar>
    </template>
    <v-content>
      <router-view />
    </v-content>
    <v-footer
      height="auto"
      color="grey darken-4"
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          d-flex
          xs12
          sm4
          md4
          order-xs2
          order-sm1
        >
          <v-card
            flat
            class="transparent"
          >
            <v-container grid-list-md>
              <v-layout column>
                <v-flex xs12>
                  <v-responsive contain>
                    <v-img
                      height="110px"
                      src="@/assets/xox-logo-2022.png"
                      contain
                    />
                  </v-responsive>
                </v-flex>
                <v-flex xs12>
                  <v-responsive contain>
                    <v-img
                      height="83px"
                      src="@/assets/onexox-logo-teal-2022.png"
                      contain
                    />
                  </v-responsive>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          d-flex
          xs12
          sm8
          md8
          order-xs1
          order-sm2
        >
          <v-container grid-list-xs>
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                sm6
              >
                <v-card
                  flat
                  class="transparent"
                >
                  <v-card-text>
                    <div class="grey--text">
                      <div><strong class="white--text">Address:</strong></div>
                      <div v-html="claimerAddress" />
                      <div>No.8, Persiaran Tropicana,</div>
                      <div>Tropicana Golf &amp; Country Resort,</div>
                      <div>47410 Petaling Jaya,</div>
                      <div>Selangor.</div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <v-card
                  flat
                  class="transparent"
                >
                  <v-card-text>
                    <div>
                      <div
                        class="grey--text"
                        v-html="telephone"
                      />
                      <div
                        class="grey--text"
                        v-html="support"
                      />
                      <div
                        class="grey--text"
                        v-html="email"
                      />
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
        <v-flex
          d-flex
          xs12
          sm12
          md12
          order-xs3
          order-sm3
        >
          <v-layout row>
            <v-spacer />
            <div>&copy; 2019&nbsp;</div>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-footer>
    <!-- <v-dialog
      v-model="showRetiringEpicDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Retiring Epic Home
        </v-card-title>
        <v-card-text>
          <v-alert
            :value="true"
            type="info"
            transition="scale-transition"
          >
            {{ retiringMessage }}
          </v-alert>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="showRetiringEpicDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            flat
            @click="jumpToEpic()"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog
      v-model="password"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Change Password
        </v-card-title>
        <v-alert
          :value="alert"
          type="error"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-alert
          :value="!alert"
          type="info"
        >
          Your password was earlier reset and sent to you via SMS. You need to
          change your password now.
          <br>
          Passwords must contain at least 8 characters, including uppercase,
          lowercase letters, numbers and special characters.
        </v-alert>
        <v-card-text>
          <v-form
            ref="passwordForm"
            v-model="validPassword"
            lazy-validation
          >
            <v-container grid-list-xs>
              <v-layout
                row
                wrap
              >
                <v-flex xs12>
                  <v-text-field
                    v-model="password1"
                    :append-icon="show1 ? 'visibility' : 'visibility_off'"
                    :rules="passwordRules"
                    :type="show1 ? 'text' : 'password'"
                    label="Enter new password"
                    hint="At least 8 characters including uppercase, lowercase letters, numbers and special characters"
                    counter
                    required
                    lazy-validation
                    @click:append="show1 = !show1"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="password2"
                    :append-icon="show2 ? 'visibility' : 'visibility_off'"
                    :rules="passwordMatchRules"
                    :type="show2 ? 'text' : 'password'"
                    label="Re-enter password"
                    counter
                    required
                    @click:append="show2 = !show2"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!validPassword"
            color="primary"
            flat
            @click="changePassword()"
          >
            Change Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="mysiswaDealer"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">Verifiy MySiswa</span>
        </v-card-title>
        <v-alert
          :value="true"
          type="info"
        >
          Are you student?
        </v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            flat
            @click="notStudent()"
          >
            No
          </v-btn>
          <v-btn
            color="red darken-1"
            flat
            @click="studentDialog = !studentDialog"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="studentDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          MySiswa Student
        </v-card-title>
        <v-alert
          :value="alert"
          type="error"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-alert
          :value="!alert"
          type="info"
        >
          It is require to fill in the blanks for mysiswa dealer.
        </v-alert>
        <v-card-text>
          <v-flex xs12>
            <v-text-field
              v-model="studentId"
              label="Enter studentID"
              required
              single-line
              box
              validate-on-blur
            />
          </v-flex>
          <v-flex xs12>
            <v-select
              v-model="state"
              :items="uniState"
              :menu-props="{ maxHeight: '250' }"
              item-text="statename"
              item-value="idstate"
              dense
              box
              label="Select university`s state"
              validate-on-blur
            />
          </v-flex>
          <v-flex>
            <v-select
              v-model="university"
              :items="list"
              :menu-props="{ maxHeight: '270' }"
              item-value="idipta"
              item-text="iptaname"
              label="Select university"
              :rules="[(val) => !!val || 'University required!']"
              hint="Select the university's state first then university"
              required
              dense
              box
              validate-on-blur
            />
          </v-flex>
          <v-flex xs12>
            <v-autocomplete
              v-model="yearOfStudy"
              :items="items"
              :menu-props="{ maxHeight: 'auto' }"
              combobox
              dense
              box
              label="Select year of study"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="studentFront"
              label="Upload student card"
              @click="onPickFile"
              :rules="[(val) => !!val || 'Student card front required!']"
              prepend-icon="attach_file"
              required
              box
            />
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              accept="image/*"
              @change="onFilePicked"
            >
            <v-img
              :src="url"
              contain
              max-height="300"
            />
          </v-flex>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="addMySiswa()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rocDealer"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">Company Details</span>
        </v-card-title>
        <v-alert
          :value="alert"
          type="error"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-alert
          :value="!alert"
          type="info"
        >
          It is require to fill in the blanks.
        </v-alert>
        <v-card>
          <v-card-text>
            <v-flex xs12>
              <v-text-field
                v-model="dealerName"
                label="Name"
                readonly
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="roc"
                label="ROC No."
                readonly
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="dealerTax"
                label="Tax Identification No. (TIN)"
                required
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="dealerSst"
                label="SST No."
                required
              />
            </v-flex>
          </v-card-text>
          <v-divider />
          <template>
            <v-container fluid>
              <v-checkbox
                v-model="checkbox"
                :true-value="1"
                :false-value="0"
                :rules="[v => !!v || 'Anda mesti bersetuju untuk meneruskan!']"
              >
                <template #label>
                  <div>
                    Saya mengesahkan bahawa maklumat yang telah dilengkapkan oleh saya adalah benar, tepat dan lengkap.
                    Jika maklumat yang diberikan tidak benar, saya boleh dikenakan tindakan.
                  </div>
                </template>
              </v-checkbox>
            </v-container>
          </template>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              flat
              @click="close"
            >
              Skip
            </v-btn>
            <v-btn
              color="primary"
              flat
              @click="addRocDetail()"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <!--address-dialog :update-address.sync="updateAddress" /-->
    <required-action
      v-for="(requiredAction, i) in requiredActions"
      :key="i"
      v-model="requiredAction.show"
      :required-action="requiredAction"
    />
    <announcement
      v-for="item in announcements"
      :key="item.title"
      v-model="item.show"
      :content="item"
    />
    <abacTnc
      v-for="item in abacTncs"
      :key="item.title"
      v-model="item.show"
      :content="item"
    />
    <validate-downgrade
      v-for="item in downgradeBDealer"
      :key="item.titlez"
      v-model="item.show"
      :content="item"
    />
    <v-dialog
      v-model="consentDealer"
      width="500"
      persistent
    >
      <consent-pdpa
        @closeDialog="closeConsent"
        @snackbar="successConsent"
      />
    </v-dialog>
    <!-- <v-dialog
      v-model="newDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      max-width="550px"
    >
      <my-siswa-dealer-dialog
        v-model="items"
        :dialog.sync="newDialog"
      />
    </v-dialog> -->
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="success"
      top
    >
      {{ snackbarText }}
      <v-btn
        flat
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <dcp v-model="dcp" />
  </v-app>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'
import { getRm } from '@/utils'
import security from '@/security'
// import store from '@/store'

const defaultDashboardName = 'dashboard.default'
const xforceDashboardName = 'dashboard.xforce'
const supportDashboardName = 'dashboard.support'
const operationDashboardName = 'dashboard.operation'
const managementDashboardName = 'dashboard.management'
const financeDashboardName = 'dashboard.finance'
const snmDashboardName = 'dashboard.snm'
const technicalDashboardName = 'dashboard.technical'
const defaultDashboard = {
  name: defaultDashboardName,
  title: 'Default',
  description: 'User: Dealers & mere subscribers',
  icon: 'mdi-view-dashboard',
  color: 'teal lighten-2',
  to: '/',
}

export default {
  name: 'App',
  components: {
    Dcp: () => import(/* webpackChunkName: "dcp" */ '@/components/Dcp'),
    RequiredAction: () =>
      import(
        /* webpackChunkName: "requiredAction" */ '@/components/RequiredActionDialog'
      ),
    Announcement: () =>
      import(
        /* webpackChunkName: "announcement" */ '@/components/Announcement'
      ),
    AbacTnc: () =>
      import(/* webpackChunkName: "tncabac" */ '@/components/TncAbac'),
    ValidateDowngrade: () =>
      import(
        /* webpackChunkName : "validateDowngrade" */ '@/components/ValidateDowngrade'
      ),
    ConsentPdpa: () =>
      import(
        /* webpackChunkName : "pdpa" */ '@/components/Pdpa'
      ),
  },
  data () {
    return {
      fileName: '',
      url: '',
      fileObject: null,
      cardResult: '',
      name: '',
      size: '',
      type: '',
      lastModifiedDate: '',
      loading: false,
      hide: true,
      uniState: [],
      alertColor: 'error',
      alertText: '',
      items: ['1', '2', '3', '4', '5', '6', '7'],
      list: [],
      state: null,
      yearOfStudy: null,
      university: null,
      studentId: null,
      studentFront: null,
      dealerTax: null,
      dealerSst: null,
      newDialog: false,
      actingFor: null,
      alert: false,
      alertMessage: null,
      avatar: null,
      b89dbPin: 0,
      b89dbPinText: null,
      claimerAddress: 'Lot 5.2, 5th Floor, Menara Lien Hoe,',
      clipped: false,
      corporateItems: [
        /*
        {
          icon: 'mdi-account-tie',
          link: {
            to: '/operation/corporate_dealer'
          },
          subTitle: 'Dealer Management',
          title: 'Dealers'
        },
        {
          icon: 'mdi-account-group',
          link: {
            to: '/operation/corporate_activation'
          },
          subTitle: 'Bulk Activations',
          title: 'Activations'
        },
        */
        {
          icon: 'mdi-cash-register',
          link: {
            to: '/operation/corporate_payment',
          },
          subTitle: 'Bulk Payments',
          title: 'Payments',
        },
        // {
        //   icon: 'mdi-lock-plus',
        //   link: {
        //     to: '/operation/reserved_msisdn',
        //   },
        //   subTitle: 'Reserved from Pool',
        //   title: 'Reserved Number',
        // },
        {
          icon: 'mdi-lock-plus',
          link: {
            to: '/operation/reserved_history',
          },
          subTitle: 'Selected from Pool',
          title: 'Reserved Number',
        },
      ],
      dark: true,
      dealerMenu: false,
      dealerPin: 0,
      activeDashboard: defaultDashboardName,
      dashboards: [],
      dashboardSheet: false,
      defaultDashboard,
      dealerPinText: null,
      d225Pin: 0,
      d225PinText: null,
      dpPin: 0,
      dpPinText: null,
      hash: null,
      notsld: false,
      isFc: false,
      isSLD: false,
      SLDTier: false,
      isMySiswaSpecial: false,
      isONEFam: false,
      lead: 0,
      nuPin: 0,
      nuPinText: null,
      bPin: 0,
      bPinText: null,
      sdPin: 0,
      sdPinText: null,
      drawer: true,
      ecash: null,
      xox: true,
      email:
        '<strong class="white--text">Email: </strong><a href="mailto:dmt@onexox.my">dmt@onexox.my</a>',
      dcp: false,
      // showEpic: false,
      logoutItem: {
        icon: 'mdi-logout',
        hint: {
          html: 'Logout',
        },
      },
      miniVariant: false,
      noBlackItems: [
        {
          icon: 'mdi-shield-account',
          link: {
            to: '/black_upgrade_dreg',
          },
          subTitle: 'Upgrade DREG',
          title: 'Become Black Dealer',
        },
      ],
      announcements: [],
      abacTncs: [],
      downgradeBDealer: [],
      dealerMySiswa: [],
      onesysItems: [
        {
          title: 'Suggestion Box',
          link: {
            to: '/suggestion_box',
          },
          icon: 'mdi-archive-edit-outline',
        },
        /*
        {
          title: 'Report Site Bug',
          link: {
            href: 'mailto: onesys@onexox.my',
          },
          icon: 'bug_report',
        },
        */
      ],
      financeStatistics: [
        {
          icon: 'mdi-cash-plus',
          link: {
            to: '/finance/topup_statistics',
          },
          subTitle: 'Topup statistics',
          title: 'Topup Statistics',
        },
      ],
      snmStatistics: [
        {
          icon: 'mdi-account-outline',
          link: {
            to: '/snm/change_rank',
          },
          subTitle: 'Change Rank',
          title: 'Change Rank',
        },
      ],
      technicalStatistics: [
        {
          icon: 'mdi-account-outline',
          link: {
            to: '/technical/change_dreg',
          },
          subTitle: 'Change DREG',
          title: 'Change DREG',
        },
        {
          icon: 'mdi-credit-card-refund-outline',
          link: {
            to: '/technical/credit_adjustment',
          },
          subTitle: 'Credit Adjustment',
          title: 'Credit Adjustment',
        },
        {
          icon: 'mdi-account-reactivate-outline',
          link: {
            to: '/technical/reactivate_dealer',
          },
          subTitle: 'Reactivate Terminated Dealer',
          title: 'Reactivate Dealer',
        },
        {
          icon: 'mdi-account-reactivate-outline',
          link: {
            to: '/technical/revoke_basic_dealer',
          },
          subTitle: 'Revoke Basic for status upgrade',
          title: 'Revoke Basic Dealer',
        },
      ],
      dailyReports: [
        /*
        {
          icon: 'mdi-medical-bag',
          link: {
            to: '/management/prihatin_stat',
          },
          subTitle: 'Prihatin activations',
          title: 'Prihatin',
        },
        */
        {
          icon: 'mdi-calendar',
          link: {
            to: '/management/daily_activation',
          },
          subTitle: 'Daily activation report',
          title: 'Activations',
        },
        {
          icon: 'mdi-map-marker-multiple-outline',
          link: {
            to: '/management/activations_by_state',
          },
          subTitle: 'Daily activations & recruitments by state',
          title: 'Activations & Recruitments by State',
        },
        {
          icon: 'mdi-map-marker-multiple-outline',
          link: {
            to: '/management/daily_recruitment',
          },
          subTitle: 'Daily dealer recruitment report',
          title: 'Dealer Recruitment',
        },
        {
          icon: 'mdi-map-marker-multiple-outline',
          link: {
            to: '/management/daily_prepaid',
          },
          subTitle: 'Daily Prepaid Breakdown report',
          title: 'Dealer Prepaid',
        },
        {
          icon: 'mdi-soccer',
          link: {
            to: '/management/daily_fc_activation',
          },
          subTitle: 'Daily FC activation report',
          title: 'FC Activations',
        },
        {
          icon: 'mdi-soccer-field',
          link: {
            to: '/management/daily_fc_distribution',
          },
          subTitle: 'Daily FC plan distribution report',
          title: 'FC Plan Distribution',
        },
        /*
        {
          icon: 'mdi-pin-outline',
          link: {
            to: '/management/black_pin_usage',
          },
          subTitle: 'Daily Black Pin usage',
          title: 'Black Pin Usage',
        },
        */
      ],
      statistics: [
        {
          icon: 'mdi-account-clock',
          link: {
            to: '/support/annual_user_statistics',
          },
          title: 'Annually',
        },
        {
          icon: 'mdi-account-clock-outline',
          link: {
            to: '/support/monthly_user_statistics',
          },
          title: 'Monthly',
        },
        {
          icon: 'mdi-account-clock-inline',
          link: {
            to: '/support/weekly_user_statistics',
          },
          title: 'Weekly',
        },
        {
          icon: 'mdi-account-clock-inline',
          link: {
            to: '/support/monthly_sld_statistics',
          },
          title: 'SLD Statistics',
        },
      ],
      reports: [
        {
          icon: 'mdi-account-clock',
          link: {
            to: '/support/md_periodic_performance',
          },
          subTitle: 'MD periodic performance',
          title: 'MD Performance',
        },
        {
          icon: 'mdi-account-clock-outline',
          link: {
            to: '/support/ad_periodic_performance',
          },
          subTitle: 'AD periodic performance',
          title: 'AD Performance',
        },
        {
          icon: 'mdi-trophy',
          link: {
            to: '/support/daily_activation_ranking',
          },
          subTitle: 'Top ranked dealers',
          title: 'Daily Activation Ranking',
        },
        {
          icon: 'mdi-car-multiple',
          link: {
            to: '/support/car_bonanza',
          },
          subTitle: 'Car Bonanza Campaign',
          title: 'Car Bonanza Campaign',
        },
        {
          icon: 'mdi-list-box-outline',
          link: {
            to: '/support/user_registration',
          },
          subTitle: 'User Registration Lists',
          title: 'User Registration',
        },
        {
          icon: 'mdi-list-box-outline',
          link: {
            to: '/support/dealer_registration',
          },
          subTitle: 'Dealer Registration Lists',
          title: 'Dealer Registration Monthly',
        },
        {
          icon: 'mdi-list-box-outline',
          link: {
            to: '/support/dealer_registration_by_day',
          },
          subTitle: 'Dealer Registration Lists',
          title: 'Dealer Registration Daily',
        },
        {
          icon: 'mdi-list-box-outline',
          link: {
            to: '/support/active_record',
          },
          subTitle: 'Active Record',
          title: 'Active Record',
        },
      ],
      adminTasks: [
        /*
        {
          icon: 'mdi-format-list-checks',
          link: {
            to: '/support/dealer_announcement'
          },
          subTitle: 'Manage dealer announcements',
          title: 'Dealer Announcement'
        },
        {
          icon: 'mdi-format-list-checks',
          link: {
            to: '/support/subscriber_announcement'
          },
          subTitle: 'Manage subscriber announcements',
          title: 'Subscriber Announcement'
        }
        */
      ],
      monthlyReports: [
        {
          icon: 'mdi-calendar-blank',
          link: {
            to: '/management/monthly_activation',
          },
          subTitle: 'Monthly activation report',
          title: 'Activations',
        },
        {
          icon: 'mdi-account-star',
          link: {
            to: '/management/monthly_dealer_kpi',
          },
          subTitle: 'Monthly dealer KPI report',
          title: 'Dealer KPI',
        },
        /*
        {
          icon: 'mdi-account-star',
          link: {
            to: '/management/super_dealer',
          },
          subTitle: 'Super dealer',
          title: 'Super Dealer',
        },
        */
      ],
      reimbursementReports: [
        {
          icon: 'mdi-calendar-blank',
          link: {
            to: '/management/family_subline_reimburse',
          },
          subTitle: 'ONEfamily Reimbursement (Supplementary line) report',
          title: 'ONEfamily Reimbursement',
        },
        {
          icon: 'mdi-calendar-blank',
          link: {
            to: '/management/october_campaign_reimburse',
          },
          subTitle: 'October Free Sim Campaign report',
          title: 'October Free Sim Reimbursement',
        },
        {
          icon: 'mdi-calendar-blank',
          link: {
            to: '/management/basic_pin_mysiswa_reimburse',
          },
          subTitle: 'Basic Pin (MySiswa) reimbursement report',
          title: 'Basic Pin (MYSISWA) Reimbursement',
        },
      ],
      right: true,
      mysiswaDealer: false,
      student: null,
      studentDialog: false,
      term: false,
      checkbox: false,
      executeAlertType: 'info',
      executeAlertType2: null,
      executeAlertMessage: null,
      rocDealer: false,
      consentDealer: false,
      dealerName: null,
      roc: null,
      password: false,
      password1: null,
      password2: null,
      passwordRules: [
        (v) => (v || '').length >= 8 || 'Minimum 8 characters!',
        (v) =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
            v,
          ) || 'Invalid password!',
      ],
      requiredActions: [],
      show1: false,
      show2: false,
      showRetiringEpicDialog: false,
      showSwitchDashboard: false,
      snackbar: false,
      snackbarText: null,
      support:
        '<strong class="white--text">DMT Careline: </strong>03.7882.5066',
      telephone: '<strong class="white--text">Tel: </strong>03.7882.5000',
      // updateAddress: false,
      validPassword: true,
      xforceDashboardName,
      // retiringMessage: 'PERHATIAN: Sila ambil maklum bahawa paparan Epic Home OneSYS ini akan ditamatkan sepenuhnya pada 17 Januari 2022.Segala aktiviti berkaitan pengedar perlu dibuat melalui paparan Home (New dashboard). Harap maklum.',
      supportDashboardName,
      operationDashboardName,
      managementDashboardName,
      financeDashboardName,
      snmDashboardName,
      technicalDashboardName,
      jomBantu: false,
      tactical: false,
      demote: false,
    }
  },
  computed: {
    myName () {
      return security.me.name()
    },
    accountType () {
      const properties = security.me.getProperties()
      return properties.find((prop) => prop.name === 'account.type')
    },
    uatTester () {
      if (this.isImpersonated) return true
      const properties = security.me.getProperties()
      return properties.find((prop) => prop.name === 'uat.tester')
    },
    permissionSetIntroducer () {
      const properties = security.me.getProperties()
      return properties.find(
        (prop) => prop.name === 'dmt.permission.setIntroducer',
      )
    },
    permissionRejectMnp () {
      const properties = security.me.getProperties()
      return properties.find(
        (prop) => prop.name === 'dmt.permission.rejectMnp',
      )
    },
    dealerxox: function () {
      return security.me.claimer() === 'xox' ? this.xox : !this.xox
    },
    testxox: function () {
      return security.me.dealerId() === '01113331010'
    },
    hasSubordinate () {
      if (!this.accountType || !security.me.username()) {
        return false
      }

      const username = security.me.username()

      if (this.accountType.value === 'dominant') {
        return (
          username === security.me.msisdn() ||
          username === security.me.dealerId()
        )
      }

      return (
        this.accountType.value.indexOf('@') > -1 &&
        username.toUpperCase() === this.accountType.value.toUpperCase()
      )
    },
    areaDealer: function () {
      return security.me.dealerRank() === 'Area Dealer'
    },
    masterDealer: function () {
      return security.me.dealerRank() === 'Master Dealer'
    },
    isDominant () {
      return !this.accountType || this.hasSubordinate
    },
    xforceAdmin () {
      const properties = security.me.getProperties()
      let admin = false

      properties.forEach((property) => {
        if (
          property.name === 'dashboard.xforce' &&
          property.value === 'admin'
        ) {
          admin = true
        }
      })

      return admin
    },
    /*
    jomBantu () {
      const properties = security.me.getProperties()
      console.log('jomBantu', properties)
      return properties.find(prop =>
        prop.name === 'jombantu',
      )
    },
    */

    passwordMatchRules () {
      return [(v) => (!!v && v) === this.password1 || 'Values do not match']
    },
    ecashItems: function () {
      const menus = []
      /*
      const menus = [
        {
          icon: 'system_update',
          link: {
            to: '/prepaid_topup',
          },
          title: 'Topup',
          subTitle: 'Topup airtime using e-recharge',
        },
      ]

      this.blackDealer && menus.push(
        {
          icon: 'payment',
          link: {
            to: '/black_pay_bill',
          },
          title: 'Pay Black bill',
          subTitle: 'Pay Black bill with e-recharge',
        },
      )
      */
      menus.push(
        {
          icon: 'swap_horiz',
          link: {
            to: '/ecash_transfer',
          },
          title: 'Transfer E-recharge',
          subTitle: 'Transfer e-recharge to other dealers',
        },
        {
          icon: 'swap_horiz',
          link: {
            to: '/ecash_record',
          },
          title: 'E-recharge records',
          subTitle: 'View e-recharge transaction records',
        },
        /*
        {
          icon: 'mdi-gift-outline',
          link: {
            to: '/erecharge_donate_tkkr'
          },
          title: 'Donate to TKKR SAG',
          subTitle: 'Support our own motorsports team!'
        }
        {
          icon: 'mdi-cart',
          link: {
            to: '/ecash_purchase'
          },
          title: 'Purchase E-recharge',
          subTitle: 'Purchase e-recharge online from HQ'
        }
        */
      )

      return menus
    },
    ecashDemotedItems: function () {
      const menus = []
      menus.push(
        {
          icon: 'swap_horiz',
          link: {
            to: '/ecash_transfer_demoted',
          },
          title: 'Transfer E-recharge',
          subTitle: 'Transfer e-recharge to other dealers',
        },
        {
          icon: 'swap_horiz',
          link: {
            to: '/ecash_record_demoted',
          },
          title: 'E-recharge records',
          subTitle: 'View e-recharge transaction records',
        },
      )
      return menus
    },
    prepaidItems: function () {
      const menus = []
      // {
      //   icon: 'system_update',
      //   link: {
      //     to: '/prepaid_topup',
      //   },
      //   subTitle: 'Topup airtime using e-recharge',
      //   title: 'Topup',
      // },
      menus.push({
        icon: 'mdi-sim-outline',
        link: {
          to: '/prepaidRegistration',
        },
        subTitle: 'Register new Prepaid subscriber',
        title: 'Register Prepaid',
      })
      return menus
    },
    blackItems: function () {
      const menus = []

      if (security.roles(['sales'])) {
        menus.push({
          icon: 'mdi-cast',
          link: {
            to: '/black_register_subscriber',
          },
          subTitle: 'Register new Black subscriber',
          title: 'Register Black',
        })
      }

      this.isDominant &&
        menus.push({
          icon: 'mdi-compare',
          link: {
            to: '/black_cobp',
          },
          subTitle: 'Switch from prepaid to Black',
          title: 'COBP to Black',
        })
      /*
      menus.push(
        {
          icon: 'payment',
          link: {
            to: '/black_pay_bill',
          },
          subTitle: 'Pay Black bill with e-recharge',
          title: 'Pay Black bill',
        },
      )
      */
      this.isDominant &&
        menus.push({
          icon: 'swap_vert',
          link: {
            to: '/black_cop',
          },
          subTitle: 'Upgrade/downgrade Black plan',
          title: 'Change Black plan',
        })

      this.b89dbDealer &&
        this.isDominant &&
        menus.push({
          icon: 'mdi-pin',
          link: {
            to: '/black_pin_kits',
          },
          subTitle: 'Manage Black PIN',
          title: 'Black PIN',
        })
      return menus
    },
    basicDealer: function () {
      return false
      // return security.me.dealerRank() === 'Basic Dealer'
    },
    dealershipItems: function () {
      const menus = []
      // if (security.me.dealerRank() === 'Area Dealer' || security.me.dealerRank() === 'Master Dealer' || security.me.nuDealer()) {
      menus.push({
        icon: 'school',
        link: {
          to: '/dealership_appoint',
        },
        title: 'Appoint New Dealer',
        subTitle: 'Appoint subscriber as new dealer',
      })
      menus.push({
        icon: 'school',
        link: {
          to: '/dealership_subscription',
        },
        title: 'Annual Subscription',
        subTitle: 'Subscription for existing dealer',
      })
      // }
      menus.push(
        {
          icon: 'swap_horiz',
          link: {
            to: '/dealership_transfer_pin',
          },
          title: 'Transfer PIN',
          subTitle: 'Transfer PIN to other dealers',
        },
        {
          icon: 'mdi-view-list',
          link: {
            to: '/dealership_pin_record',
          },
          title: 'PIN Records',
          subTitle: 'View PIN transaction records',
        },
      )
      return menus
    },
    mnpKits: function () {
      const menus = [
        {
          icon: 'mdi-clipboard-flow-outline',
          link: {
            to: '/support/mnp_request_list',
          },
          title: 'Mnp Status',
        },
        {
          icon: 'mdi-sim-alert-outline',
          link: {
            to: '/support/mnp_imsi',
          },
          title: 'IMSI',
        },
        {
          icon: 'mdi-sim-alert-outline',
          link: {
            to: '/support/mnp_reject',
          },
          title: 'MNP Reject',
        },
      ]
      // this.permissionRejectMnp &&
      //   menus.push({
      //     icon: 'mdi-sim-off-outline',
      //     link: {
      //       to: '/support/reject_mnp',
      //     },
      //     title: 'Reject Mnp',
      //   })
      // this.hasRealmRole(['devop']) &&
      //   menus.push({
      //     icon: 'mdi-sim-off-outline',
      //     link: {
      //       to: '/support/reject_mnp',
      //     },
      //     title: 'Reject Mnp',
      //   })

      return menus
    },
    subscriberGroup: function () {
      const menus = [
        {
          icon: 'mdi-file-tree',
          link: {
            to: '/subscriber_tier',
          },
          title: 'Tiers',
          subTitle: 'Subscriber tiers',
        },
        {
          icon: 'mdi-account-multiple-plus-outline',
          link: {
            to: '/group_activations',
          },
          title: 'Activations',
          subTitle: 'Group activations',
        },
      ]

      this.isFc &&
        menus.push({
          icon: 'mdi-account-multiple-plus-outline',
          link: {
            to: '/fc_activations',
          },
          title: 'FC Activations',
          subTitle: 'Non-Group activations',
        })

      return menus
    },
    royaltyIncentiveItems: function () {
      const menus = []
      this.notsld &&
      menus.push(
        {
          link: {
            to: '/annual_royalty_incentive',
          },
          title: 'Annual Summary',
        },
        {
          link: {
            to: '/monthly_royalty_incentive',
          },
          title: 'Monthly Summary',
        },
        {
          link: {
            to: '/daily_royalty_incentive',
          },
          title: 'Daily Details',
        },
        {
          link: {
            to: '/subscriber_reload',
          },
          title: 'Subscriber Summary',
        },
      )

      this.isSLD &&
      menus.push(
        {
          link: {
            to: '/monthly_royalty_incentive_sld',
          },
          title: 'Monthly Summary SLD',
        },
      )

      this.SLDTier &&
      menus.push(
        {
          link: {
            to: '/daily_royalty_incentive_sld',
          },
          title: 'Daily Details SLD',
        },
      )

      return menus
    },

    financeGroup: function () {
      const menus = [
        {
          icon: 'mdi-pin-outline',
          link: {
            to: '/finance/black_pin_orders',
          },
          title: 'Black Pin',
          subTitle: 'Black pin orders',
        },
      ]

      return menus
    },
    isAuthenticated: function () {
      return this.$store.state.security.auth.authenticated
    },
    menuSize: function () {
      return this.$vuetify.breakpoint.xsOnly ? '35px' : '45px'
    },
    blackDealer: function () {
      return security.me.blackDealer()
    },
    b10Dealer: function () {
      return security.me.mobilePlan() === 'B10'
    },
    b89dbDealer: function () {
      return (
        security.me.mobilePlan() === 'B89' ||
        security.me.mobilePlan() === 'B89DB' ||
        security.me.mobilePlan() === 'B39_PLUS50' ||
        security.me.mobilePlan() === 'B149' ||
        this.b89dbPin ||
        security.me.dealerId() === 'TKKR1' ||
        security.me.dealerId() === 'D000000'
      )
    },
    title: function () {
      const title =
        this.isAuthenticated && security.me.dealerId()
          ? security.me.dealerId()
          : security.me.msisdn()
            ? security.me.msisdn()
            : 'OneSYS'
      return this.isImpersonated ? 'Viewing ' + title : title
    },
    isImpersonated: function () {
      return this.$store.state.security.impersonate !== null
    },
  },
  watch: {
    '$store.state.security.properties': function (properties) {
      const dashboards = properties.filter(
        (prop) => prop.name.substring(0, 10) === 'dashboard.',
      )

      this.dashboards = []
      dashboards.forEach((dashboard) => {
        if (dashboard.name === xforceDashboardName) {
          this.dashboards.push({
            name: dashboard.name,
            value: dashboard.value,
            description: 'Team: X-Force internal admin & lead dealers',
            title: 'X-Force',
            icon: 'mdi-office-building-marker',
            color: 'lime darken-2',
            to: '/xforce',
          })
        }
      })

      if (this.hasResourceRole(['impersonation'], 'realm-management')) {
        this.dashboards.push({
          name: supportDashboardName,
          title: 'Internal Support',
          description: 'Team: DMT & Channel Support',
          icon: 'mdi-headset',
          color: 'pink',
          to: '/support',
        })
      }

      if (this.hasRealmRole(['operation'])) {
        this.dashboards.push({
          name: operationDashboardName,
          title: 'Operation',
          description: 'Team: Operation',
          icon: 'mdi-engine',
          color: 'indigo lighten-2',
          to: '/operation',
        })
      }

      if (this.hasRealmRole(['snm'])) {
        this.dashboards.push({
          name: snmDashboardName,
          title: 'Snm',
          description: 'Team: Snm',
          icon: 'mdi-bullseye-arrow',
          color: 'black',
          to: '/snm',
        })
      }
      if (this.hasRealmRole(['devop'])) {
        this.dashboards.push({
          name: technicalDashboardName,
          title: 'Technical',
          description: 'Team: Technical',
          icon: 'mdi-wrench',
          color: 'white',
          to: '/technical',
        })
      }

      if (this.hasRealmRole(['management'])) {
        this.dashboards.push({
          name: managementDashboardName,
          title: 'Management',
          description: 'Team: Management',
          icon: 'mdi-chart-areaspline',
          color: 'orange lighten-2',
          to: '/management',
        })
      }

      if (this.hasRealmRole(['management']) || this.hasRealmRole(['finance'])) {
        this.dashboards.push({
          name: financeDashboardName,
          title: 'Finance',
          description: 'Team: Finance',
          icon: 'mdi-finance',
          color: 'deep-purple lighten-2',
          to: '/finance',
        })
      }

      if (this.dashboards.length > 0) {
        this.dashboards.push(defaultDashboard)
        this.showSwitchDashboard = true
      } else {
        this.showSwitchDashboard = false
      }
    },

    state: function (val) {
      this.getIpta(val)
    },
    hash: function (val) {
      /*
      if (val && !this.isImpersonated) {
        const user = {
          name: security.me.dealerId() + ': ' + security.me.name(),
          email: security.me.email(),
          hash: val,
        }
        this.$Tawk.$setAttributes(user)
      }
      */
    },
    isAuthenticated: function (val) {
      if (val) {
        this.getResources()
        this.getAvatar()
        this.getRequiredActions()
        this.getAnnouncements()
        this.getabacTncs()
        this.getdowngradeBDealer()
        this.getMySiswaDealer()
        this.getUniversityState()
        this.clearSession()
        this.getRocDealer()
      } else {
        // this.$Tawk.$logout()
        this.hash = null
      }
    },
    isImpersonated: function () {
      this.getResources()
      this.getAvatar()
    },
    /*
    updateAddress: function (val) {.
      if (!val) {
        this.snackbarText = 'You have successfully updated your address.'
        this.snackbar = true
      }
    },
    */
  },
  methods: {
    closeConsent: function () {
      this.consentDealer = false
    },
    successConsent: function () {
      this.snackbar = true
      this.snackbarText = 'Your declaration form has been submitted and properly recorded in the system.'
    },
    close: function () {
      this.rocDealer = false
    },
    onPickFile () {
      this.$refs.fileInput.click()
    },
    onFilePicked (event) {
      const files = event.target.files
      if (files[0] !== undefined) {
        this.studentFront = files[0].name
        // Check validity of file
        if (this.studentFront.lastIndexOf('.') <= 0) {
          return
        }
        // If valid, continue
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.url = fr.result
          this.fileName = this.url
          this.fileObject = files[0] // this is an file that can be sent to server...
        })
      } else {
        this.fileName = ''
        this.fileObject = null
        this.url = ''
      }
    },
    // jumpToEpic () {
    //   this.showRetiringEpicDialog = false
    //   window.location.href = 'https://onesys.onexox.my/epic/'
    // },
    clearSession () {
      this.$rest.get('clearStart.php', createGetParams({}))
    },

    getIpta () {
      this.loading = true
      const params = createGetParams({
        idstate: this.state,
      })
      this.$rest
        .get('getUniversityName.php', params)
        .then(
          function (response) {
            this.list = response.data
            this.loading = false
          }.bind(this),
        )
        .catch((error) => {
          this.noDataText = error.message
          this.loading = false
        })
    },

    async getRequiredActions () {
      try {
        const params = createGetParams({})
        const response = await this.$rest.get('getRequiredActions.php', params)
        this.requiredActions = response.data
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    toggleTawk: function () {
      // this.$Tawk.$toggle()
    },
    switchDashboard: function (dashboard) {
      this.dashboardSheet = false
      this.$router.push(dashboard.to).catch(() => {})
      this.activeDashboard = dashboard.name
      setTimeout(() => {
        this.drawer = true
      }, 300)
    },
    getAnnouncements: function () {
      this.$rest
        .get('getAnnouncements.php', createGetParams({}))
        .then((response) => {
          this.announcements = response.data
        })
        .catch((e) => {
          this.announcements = []
        })
    },
    getabacTncs: function () {
      this.$rest
        .get('getPopupAbac.php', createGetParams({}))
        .then((response) => {
          this.abacTncs = response.data
        })
        .catch((e) => {
          this.abacTncs = []
        })
    },
    getdowngradeBDealer: function () {
      this.$rest
        .get('getDowngradeBasicDealer.php', createGetParams({}))
        .then((response) => {
          this.downgradeBDealer = response.data
        })
        .catch((e) => {
          this.downgradeBDealer = []
        })
    },
    getMySiswaDealer: function () {
      this.$rest
        .get('getDealerMySiswa.php', createGetParams({}))
        .then((response) => {
          this.dealerMySiswa = response.data
        })
        .catch((e) => {
          this.dealerMySiswa = []
        })
    },
    getRocDealer: function () {
      const params = createGetParams({
        profiling: true,
      })

      this.$rest.get('getProfile.php', params)
        .then(response => {
          this.dealerName = response.data.name
          this.roc = response.data.roc
        })
    },
    getUniversityState: function () {
      this.$rest
        .get('getUniversityState.php', createGetParams({}))
        .then((response) => {
          this.uniState = response.data
        })
        .catch((e) => {
          this.uniState = []
        })
    },
    getAvatar: function () {
      this.$rest
        .get('getAvatar.php', createGetParams({}))
        .then((response) => {
          this.avatar = response.data.avatar
        })
        .catch((e) => {
          this.avatar = null
        })
    },
    getResources: function () {
      const params = createGetParams({
        erecharge: true,
        dealership_pin: true,
        b89db_pin: true,
        email: security.me.email(),
      })
      this.$rest
        .get('getDealerResources.php', params)
        .then(
          function (response) {
            if (this.isImpersonated) {
              this.showSwitchDashboard = false
              this.activeDashboard = defaultDashboard.name
            } else {
              this.$store.dispatch('setProperties', response.data.props)
            }
            this.jomBantu = response.data.props.find(
              (prop) => prop.name === 'jombantu',
            )
            this.tactical = response.data.props.find(
              (prop) => prop.name === 'tactical.campaign',
            )
            this.demote = response.data.demote
            this.ecash =
              '<span style="color:#6882FF">' +
              getRm(response.data.erecharge) +
              '</span>'
            this.d225Pin = response.data.d2225Pin
            this.d225PinText =
              '<span style="color:#6882FF">' +
              response.data.d225Pin +
              '</span>'
            this.dpPin = response.data.dpPin
            this.dpPinText =
              '<span style="color:#6882FF">' + response.data.dpPin + '</span>'
            this.dealerPin = response.data.dealerPin
            this.dealerPinText =
              '<span style="color:#6882FF">' +
              response.data.dealerPin +
              '</span>'
            this.bPin = response.data.bPin
            this.bPinText =
              '<span style="color:#6882FF">' + response.data.bPin + '</span>'
            this.sdPin = response.data.sdPin
            this.sdPinText =
              '<span style="color:#6882FF">' + response.data.sdPin + '</span>'
            this.nuPin = response.data.nuPin
            this.nuPinText =
              '<span style="color:#6882FF">' + response.data.nuPin + '</span>'
            this.b89dbPin = response.data.b89dbPin
            if (response.data.hash) {
              this.hash = response.data.hash
            }
            // if (response.data.showEpic) {
            //   this.showEpic = response.data.showEpic
            // }
            // if (response.data.retiringMessage) {
            //   this.retiringMessage = response.data.retiringMessage
            // }
            this.b89dbPinText =
              '<span style="color:#6882FF">' +
              response.data.b89dbPin +
              '</span>'
            this.lead = response.data.lead
            response.data.runDcp &&
              this.$nextTick(() => {
                this.dcp = response.data.runDcp
              })
            if (response.data.changePassword) {
              this.password = true
            }
            if (response.data.mysiswaDealer) {
              this.mysiswaDealer = true
            }
            if (response.data.rocDealer) {
              this.rocDealer = true
            }
            if (response.data.consentDealer) {
              this.consentDealer = true
            }

            this.notsld = response.data.notsld
            this.isFc = response.data.isFc
            this.isSLD = response.data.isSLD
            this.SLDTier = response.data.SLDTier
            this.isMySiswaSpecial = response.data.isMySiswaSpecial
            this.isONEFam = response.data.isONEFam
            /*
        if (response.data.updateAddress) {
          this.updateAddress = true
        }
        */
          }.bind(this),
        )
        .catch((error) => {
          this.ecash = '<span style="color:red">' + error.message + '</span>'
        })
    },
    hasRealmRole: function (role) {
      return security.roles(role)
    },
    hasResourceRole: function (role, resource) {
      return security.roles(role, resource)
    },
    isNuDealer: function () {
      return security.me.nuDealer()
    },
    isPureNuDealer: function () {
      return security.me.pureNuDealer()
    },
    login: function () {
      this.$router.push('/login')
    },
    logout: function () {
      // this.$Tawk.$logout()
      security.logout()
    },
    toggleTheme: function () {
      this.dark = !this.dark
    },
    exitImpersonation: function () {
      this.$store.dispatch('impersonate', null)
      this.$router.push('/support/subscription_list')
      this.activeDashboard = this.supportDashboardName
      this.showSwitchDashboard = true
    },
    showError (message) {
      this.alertMessage = message
      this.alert = true
    },
    async changePassword () {
      if (!this.$refs.passwordForm.validate()) return
      try {
        const params = createPostParams({
          action: 'changePassword',
          payload: {
            password: this.password2,
          },
        })
        await this.$rest.post('postAction.php', params)

        this.password = false
        this.snackbarText = 'You have successfully changed your password.'
        this.snackbar = true
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    async notStudent () {
      this.mysiswaDealer = false
      try {
        const params = createPostParams({
          action: 'addMySiswa',
          payload: {
            student: 'N',
          },
        })
        await this.$rest.post('postAction.php', params)
        this.snackbarText = 'Thank you for your respond.'
        this.snackbar = true
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    async addMySiswa () {
      this.mysiswaDealer = false
      try {
        const params = createPostParams({
          action: 'addMySiswa',
          payload: {
            idiptas: this.university,
            studentId: this.studentId,
            yearOfStudy: this.yearOfStudy,
            studentIdFrontImage: this.fileName,
            student: 'Y',
          },
        })
        await this.$rest.post('postAction.php', params)
        this.studentDialog = false
        this.snackbarText = 'You have successfully update your mysiswa detail.'
        this.snackbar = true
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    async addRocDetail () {
      const params = createPostParams({
        action: 'addRocDetail',
        payload: {
          tax: this.dealerTax,
          sst: this.dealerSst,
        },
      })

      if (this.checkbox === 1) {
        try {
          await this.$rest.post('postAction.php', params)
          this.rocDealer = false
          this.snackbarText = 'You have successfully update your company detail.'
          this.snackbar = true
        } catch (e) {
          let message = e.message
          if (e.response) {
            message += ': ' + e.response.data
          }
          this.showError(message)
        } finally {
          this.loading = false
        }
      } else {
        this.executeAlertType2 = 'error'
        this.executeAlertMessage = 'Anda mesti bersetuju untuk meneruskan!'
      }
    },
  },
}
</script>

<style>
#toolbar-image {
  background: url("/img/background.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
